<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <!-- <Button
          label="Add New"
          icon="pi pi-plus"
          class="ml-2 p-button-success"
          @click="$router.push('contact/add')"
        /> -->
        <!-- <Button
          label="حذف"
          icon="pi pi-trash"
          @click="deleteAll"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        /> -->
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
        />
        <!-- <Button icon="pi pi-search" class="ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه الطلبات
          <div style="text-align: left">
            <!-- <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="Select Columns"
              style="width: 20em"
            /> -->
          </div>
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>

      <Column filterMatchMode="contains" field="id" header="#" :sortable="true">
        <template #filter>
          <InputText
            v-model="filters['id']"
            class="p-column-filter"
            filterMatchMode="contains"
            placeholder="بحث ب #"
          />
        </template>
        <template #body="slotProps">
          <p>
            {{ slotProps.data.id }}
          </p>
        </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="name"
        header="الاسم"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['name']"
            class="p-column-filter"
            filterMatchMode="contains"
            placeholder="بحث بالاسم"
          />
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="phone"
        header="الهاتف"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['phone']"
            class="p-column-filter"
            placeholder="بحث بالهاتف"
          />
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="productsId"
        header="المنتج"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="subject"
            v-model="filters['productsId.name']"
            filterMatchMode="contains"
            class="p-column-filter"
            placeholder="بحث بالمنتج"
          />
        </template>
        <template #body="slotProps">
          <p v-if="slotProps.data.productsId">
            {{ slotProps.data.productsId.id }} -
            {{ slotProps.data.productsId.name }}
          </p>
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="createdAt"
        header="تاريخ الارسال"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="date"
            v-model="filters['createdAt']"
            class="p-column-filter"
            placeholder="بحث بتاريخ الارسال"
          />
        </template>
        <template #body="slotProps">
          {{ $durationFormatDate(slotProps.data.createdAt) }}
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="[slotProps.data]"
            class="p-datatable-customers table table-striped"
          >
            <template #empty> No Data </template>

            <Column filterMatchMode="contains" field="note" header="الملاحظه" />
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],

      expandedRows: [],
      loading: true,
      filters: {},
      displayProductsAdd: false,
      d: {
        id: null,
        messageReply: null,
        name: null,
        subject: null,
      },
    };
  },
  methods: {
    getData() {
      this.$http.get(`orders`).then(
        (response) => {
          this.loading = false;
          this.list = response.data.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },

    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    this.getData();
    this.selectedColumns = this.columns;
  },
};
</script>

<style></style>
